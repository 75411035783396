.c-phone-field {
  --c-phone-field-country-selector-code-margin-inline-end: 15px;

  .country-selector {
    opacity: 1 !important;
    width: inherit !important;
  }

  .mdc-button__label {
    align-items: center;
    display: flex;
  }

  .country-selector-code {
    color: rgba(0, 0, 0, .87);
    font-family: 'Manrope', serif;
    margin-inline-end: var(--c-phone-field-country-selector-code-margin-inline-end);
  }
}

.ngx-mat-tel-input-container {
  align-items: center;
  display: flex;
}

.ngx-mat-tel-input-mat-menu-panel {
  margin-block: 10px;

  .mat-mdc-menu-content {
    .country-search {
      outline: none;
    }
  }
}
