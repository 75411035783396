@layer components {
  .c-product-item__wrapper {
    --_c-product-item-image-height: 40px;
    --_c-product-item-image-shadow-width: 2px;
    --_c-product-item-wrapper-padding-top: calc((var(--_c-product-item-image-height) / 2) + var(--_c-product-item-image-shadow-width));
    padding-top: var(--_c-product-item-wrapper-padding-top);
  }

  .c-product-item {
    --_c-product-item-background-color: var(--color-neutral-100);
    --_c-product-item-header-height: 3lh;
    --_c-product-item-header-column-gap: var(--space-xs);
    --_c-product-item-image-background-color: var(--color-primary);
    --_c-product-item-image-border-color: var(--color-neutral-100);
    --_c-product-item-body-text-color: var(--color-neutral-10);
    --_c-product-item-info-background-color: var(--color-background-primary-weak);
    --_c-product-item-info-border-radius: var(--border-radius-normal);
    --_c-product-item-info-column-gap: var(--space-xxs);
    --_c-product-item-description-padding: var(--space-xs);
    --_c-product-item-body-padding: 0;
    --_c-product-item-actions-color: var(--color-neutral-10);
    --_c-product-item-price-color: var(--color-secondary);
    --_c-product-item-price-font-size: var(--font-size-xxxl);
    --_c-product-item-price-font-weight: var(--font-weight-light);
    --_c-product-item-price-width: min-content;
    --_c-product-item-row-gap: var(--space-xs);
    --_c-product-item-quantity-value-color: var(--color-neutral-10);
    --_c-product-item-quantity-value-font-size: var(--font-size-xxxl);
    --_c-product-item-quantity-value-font-weight: var(--font-weight-light);

    background-color: var(--_c-product-item-background-color);
    border-radius: 20px;
    padding: {
      block-start: 30px;
      block-end: 20px;
      inline: 20px;
    }
    position: relative;
  }

  .c-product-item:not(.c-product-item--expanded) {
    --_c-product-item-body-height: 0;
  }

  .c-product-item--expanded {
    --_c-product-item-body-height: auto;
    display: grid;
    row-gap: var(--_c-product-item-row-gap);
  }

  .c-product-item__header {
    align-items: center;
    column-gap: var(--_c-product-item-header-column-gap);
    display: grid;
    grid-template-columns: auto min-content;

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      column-gap: 3px;
    }
  }

  .c-product-item__image {
    align-content: center;
    align-items: center;
    background-color: var(--_c-product-item-image-background-color);
    border-radius: calc(var(--_c-product-item-image-height) / 2);
    box-shadow: 0 0 0 var(--_c-product-item-image-shadow-width) var(--_c-product-item-image-border-color);
    display: flex;
    height: var(--_c-product-item-image-height);
    justify-content: center;
    left: 50px;
    padding-inline: 20px;
    position: absolute;
    top: 0;
    translate: 0 -50%;
  }

  .c-product-item__image-img {
    display: block;
    height: 50%;
    object-fit: contain;
    width: auto;
  }

  .c-product-item__info {
    align-items: center;
    column-gap: var(--_c-product-item-info-column-gap);
    contain: paint;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 24px var(--_c-product-item-price-width);

    // TODO: Use Container Query!
    @media (min-width: 1200px) {
      grid-template-columns: auto var(--_c-product-item-price-width);
      pointer-events: none;
    }

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      --_c-product-item-info-column-gap: 0;
      grid-template-columns: auto 20px var(--_c-product-item-price-width) !important;
    }
  }

  .c-product-item__description {
    align-items: center;
    display: flex;
    height: 3.5lh;

    // TODO: Use Container Query!
    @media (min-width: 1200px) {
      min-height: 4.5lh;
    }

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      font-size: 13px; // TODO: Use variable! (--font-size-xxs)? --> See src/assets/scss/settings/_typography.scss (All items in a SassMap are compiled into Custom CSS Properties, find in DevTools)
    }
  }

  .c-product-item__description-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-neutral-10);
    // font-weight: var(--font-weight-medium);

    // TODO: Use Container Query!
    @media (min-width: 1200px) {
      -webkit-line-clamp: unset;
    }
  }

  .c-product-item__actions {
    align-items: center;
    color: var(--_c-product-item-actions-color);
    display: flex;
    justify-content: center;

    // TODO: Use Container Query!
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .c-product-item__price {
    align-items: center;
    color: var(--_c-product-item-price-color);
    display: grid;
    font: {
      size: var(--_c-product-item-price-font-size);
      weight: var(--_c-product-item-price-font-weight);
    }
    height: 100%;
    justify-content: center;
    padding-inline: 5px;
    min-width: 70px;

    @media (min-width: 768px) {
      min-width: 90px;
    }

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      min-width: 60px;
      font-size: var(--font-size-xs);
    }
  }

  .c-product-item__quantity {
    column-gap: 5px; // TODO: Switch to Container Query, and use clamp() with cqw unit!
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      column-gap: 3px; // TODO: When switch to Container Query, and clamp() this will be removed!
    }
  }

  .c-product-item__quantity-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;

    @media (max-width: 360px) {
      width: 54px;
    }
  }

  .c-product-item__quantity-value-mat-mdc-form-field {
    --mdc-outlined-text-field-container-shape: 0 !important;
    font-family: inherit !important;

    &:focus-within {
      box-shadow: none !important;
    }

    .mat-mdc-text-field-wrapper {
      background-color: var(--color-transparent, transparent) !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .mat-mdc-input-element {
      color: var(--_c-product-item-quantity-value-color) !important;
      font: {
        size: var(--_c-product-item-quantity-value-font-size) !important;
        weight: var(--_c-product-item-quantity-value-font-weight) !important;
      }
      text-align: center;
    }

    .mat-mdc-input-element:-webkit-autofill,
    .mat-mdc-input-element:autofill,
    .mat-mdc-input-element:-internal-autofill-selected {
      -webkit-text-fill-color: var(--_mat-mdc-form-field-autofill-color);
      box-shadow: 0 0 40rem #f00 inset !important;
    }
  }

  .c-product-item__body {
    // background-color: var(--_c-product-item-info-background-color);
    display: grid;
    // font-weight: var(--font-weight-medium);
    transition: height 0.3s ease-in-out;
    padding: var(--_c-product-item-body-padding);
    color: var(--_c-product-item-body-text-color);

    // TODO: Use Container Query!
    @media (max-width: 460px) {
      font-size: var(--font-size-xs);
    }
  }
}
